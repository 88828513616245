<template>
  <b-card-code
    no-body
    title="Admin List"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">

        <div class="col-md-12">
          <b-modal
            id="form-Admin-modal"
            no-close-on-backdrop
            size="lg"
            :title="editId != null ? 'Edit Admin' : 'Add Admin'"
          >
            <div class="form">

              <h5>Account Information</h5>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Name:</label>
                    <validation-provider
                      name="Admin name..."
                    >
                      <b-form-input
                        v-model="formPayload.name"
                        :state="validations.name ? false:null"
                        placeholder="Admin name..."
                      />
                      <small
                        v-for="(validation, index) in validations.name"
                        v-if="validations.name"
                        :key="`errorName${index}`"
                        class="text-danger"
                      >{{ validation }}</small>
                    </validation-provider>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Email:</label>
                    <validation-provider
                      name="Admin email..."
                    >
                      <b-form-input
                        v-model="formPayload.email"
                        :state="validations.email ? false:null"
                        placeholder="Admin email..."
                      />
                      <small
                        v-for="(validation, index) in validations.email"
                        v-if="validations.email"
                        :key="`errorEmail${index}`"
                        class="text-danger"
                      >{{ validation }}</small>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">Password:</label>
                <input
                  v-model="formPayload.password"
                  type="password"
                  class="form-control"
                  placeholder="Admin password..."
                >
              </div>

              <div class="form-group">
                <label for="">Password Confirmation:</label>
                <input
                  v-model="formPayload.password_confirmation"
                  type="password"
                  class="form-control"
                  placeholder="Admin password..."
                >
              </div>

              <!-- Roles -->
              <br>
              <h5>Roles</h5>

              <div class="row">

                <div
                  v-for="role in roles"
                  :key="role.id"
                  class="col-3"
                >
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input
                        :id="'role_checkbox_' + role.id"
                        v-model="formPayload.roles"
                        class="custom-control-input"
                        type="checkbox"
                        :value="role.id"
                      >
                      <label
                        class="custom-control-label text-capitalize"
                        :for="'role_checkbox_' + role.id"
                      >
                        {{ role.name }}
                      </label>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <template #modal-footer="{}">
              <b-button
                v-if="editId == null"
                variant="success"
                @click="createItem()"
              >
                Save Admin
              </b-button>
              <b-button
                v-else
                variant="success"
                @click="updateItem()"
              >
                Save Admin
              </b-button>
            </template>
          </b-modal>
        </div>

        <div class="col-12">
          <div class="form-row">
            <div class="col-lg-4">
              <b-button
                v-if="checkPermission('create admin')"
                v-b-modal.form-Admin-modal
                class="btn btn-info btn-sm mb-1"
                data-toggle="tooltip"
                data-placement="top"
                title="Add Admin"
                @click="cleanUpForm()"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                />
                Add Admin
              </b-button>
            </div>
            <div class="col-lg-4" />
            <div class="col-lg-4 mb-1 mb-lg-0">
              <input
                v-model="filter.keyword"
                type="text"
                placeholder="Search by name or email"
                class="form-control form-control-sm float-right"
                style="width: 200px;"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>#</th>
            <th>Admin</th>
            <th>Role</th>
            <th>Registration Time</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="5"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="(item, index) in result.data"
            v-else
            :key="item.id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}<br><small>{{ item.email }}</small></td>
            <td>
              <span
                v-for="role in item.roles"
                :key="role"
                class="badge text-capitalize badge-light-success badge-pill"
              >{{ role }}</span>
            </td>
            <td>{{ item.registered_at == null ? '-' : item.registered_at | formatDateRegister }}</td>
            <td>
              <b-dropdown
                variant="info"
                size="sm"
              >
                <template #button-content>
                  Action
                </template>
                <b-dropdown-item
                  v-if="checkPermission('update admin')"
                  @click="editItem(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-75"
                  />
                  Edit Admin
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('delete admin') && !item.roles.includes('super admin')"
                  @click="deleteItem(item.uuid)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-75"
                  />
                  Delete Admin
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="5"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Admin List`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormInput,
    ValidationProvider,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        keyword: '',
      },
      isLoading: false,
      formPayload: Object,
      editId: null,
      roles: [],
      validations: '',
    }
  },
  created() {
    this.getData()
    this.loadFormData()

    if (!checkPermission('show admins')) {
      window.location.href = `${window.location.origin}/error-403`
    }
  },
  methods: {
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        is_active: true,
        roles: [],
      }
      this.validations = ''
    },
    loadFormData() {
      // Load Roles
      this.$http.get('/admin/acl/roles')
        .then(response => {
          this.roles = response.data.data.data
        })
    },
    createItem() {
      const form = this.preparePayload()

      if (this.formPayload.roles.length > 0) {
        this.$http.post('/admin/users', form, {
          headers: { 'content-type': 'multipart/form-data' },
        })
          .then(response => {
            this.$bvModal.hide('form-Admin-modal')
            this.getData(this.currentPage)
            successNotification(this, 'Success', 'Admin successfully created')
            this.cleanUpForm()
          })
          .catch(error => {
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations
            }
          })
      } else {
        alert('Select role!')
      }
    },
    updateItem() {
      const form = this.preparePayload()
      form.append('_method', 'PATCH')

      if (this.formPayload.roles.length > 0) {
        this.$http.post(`/admin/users/${this.editId}`, form, {
          headers: { 'content-type': 'multipart/form-data' },
        })
          .then(response => {
            this.$bvModal.hide('form-Admin-modal')
            this.getData(this.currentPage)
            successNotification(this, 'Success', 'Admin successfully updated!')
            this.cleanUpForm()
          })
          .catch(error => {
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations
            }
          })
      } else {
        alert('Select role!')
      }
    },
    preparePayload() {
      this.formPayload.is_active = 1

      const form = new FormData()
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      // Prepare role payload
      this.formPayload.roles.forEach(item => {
        form.append('roles[]', item)
      })

      return form
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid

      // Getting roles id
      const roleId = []
      const context = this
      item.roles.forEach(roleName => {
        context.roles.forEach(role => {
          if (roleName == role.name) {
            roleId.push(role.id)
          }
        })
      })

      this.formPayload = {
        name: item.name,
        email: item.email,
        password: item.password,
        password_confirmation: item.password_confirmation,
        roles: roleId,
      }

      this.$bvModal.show('form-Admin-modal')
    },
    getData() { //page = 1
      this.isLoading = true
      // this.currentPage = page
      const queryParams = this.filter
      // queryParams.page = page
      queryParams.user_type = 'admin'

      this.$http.get('/admin/users', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/users/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'User successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>
